import { Col } from 'antd';
import Pricing from './Pricing';
import styled from 'styled-components';


const pricingObject: any = {
    "single": {
        data: [
        { text: 'All of Dubs premium picks for the day', value: true },
        { text: 'Daily cards with NBA, NFL, NHL, MLB, and much more', value: true },
        { text: 'Third-party verified winnings', value: true },
        { text: 'Money back guaranteed plays', value: false },
        { text: 'One on one unit management and consulting', value: false },
        { text: 'Access to Dubs Arbitrage Betting Systems (coming soon)', value: false },
      ],
    price: 20,
    duration: null,
    header: "Daily",
    subTitle: "Great for casual bettors"
},
    "premium": {
        data: [
          { text: 'All of Dubs premium picks for the week', value: true },
          { text: 'Daily cards with NBA, NFL, NHL, MLB, and much more', value: true },
          { text: 'Third-party verified winnings', value: true },
          { text: 'One on one unit management and consulting', value: true },
          { text: 'Access to Dubs Arbitrage Betting Systems (coming soon)', value: true },
          { text: 'Money back guaranteed plays', value: false },
      ],
    price: 60,
    duration: "Week",
    header: "Weekly",
    subTitle: "Ideal for seasonal bettors"
},
    "monthly": {
        data: [
          { text: 'All of Dubs premium picks for the month', value: true },
          { text: 'Daily cards with NBA, NFL, NHL, MLB, and much more', value: true },
          { text: 'Third-party verified winnings', value: true },
          { text: 'One on one unit management and consulting', value: true },
          { text: 'Access to Dubs Arbitrage Betting Systems (coming soon)', value: true },
          { text: 'Money back guaranteed plays', value: true },
      ],
    price: 200,
    duration: "Month",
    header: "Monthly",
    subTitle: "Perfect for year-round bettors"
},
    "gurantee": {
        data: [
        { text: 'whatever', value: true },
        { text: 'whatever', value: false },
        { text: 'whatever', value: false },
      ],
    price: 99.99,
    duration: "Mo.",
    header: "Monthly",
    subTitle: "Perfect year-round bettors"
}
}

interface CardProps {
    plan: string;
}

export default function DubsCard(props: CardProps){
    return (
      <ColDiv>
        <Col xs={12} md={8} lg={6} style={{width: '350px', }}>
          <Pricing
            plan={props.plan}
            data={pricingObject[props.plan].data}
            price={pricingObject[props.plan].price}
            duration={pricingObject[props.plan].duration}
            currency="$"
            buttonContent="GET STARTED"
            subTitle={pricingObject[props.plan].subTitle}
            headerText={pricingObject[props.plan].header}
          />
        </Col>
      </ColDiv>
    )
}



const ColDiv = styled.div`
@media (max-width: 767px) {
  margin: 1rem 0rem;
}
  margin: 1rem;
  border-radius: 1rem;
`
